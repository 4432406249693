import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    CardContent,
    CardHeader, Divider, Grid,
    IconButton,
    Typography
} from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {makeStyles} from "@mui/styles";
import sensor from '../../../../../assets/ic_sensor.png';
import DeviceInfoList from "./DeviceInfoList";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {
    deleteImageRequest,
    getStationImageRequest,
    stationInfoRequest, uploadImageRequest
} from "../../../../../requests/analytics/stationInfoRequest";
import {MODEL_EXTERNAL_PREFIX, stationState} from "../../../../../constants";
import UpdateAliasPopUp from "./UpdateAliasPopUp";
import {updateAliasAction} from "../../../../../reducers/dashboardUIReducer";
import {getFormattedDateTime} from "../../../../../utils/getFormattedDateTime";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CardLayout from "../../../../common/card_views/CardLayout";
import useAuth from "../../../../../hooks/useAuth";
import useMessage from "../../../../../hooks/useMessage";
import {WRITE_ALIAS} from "../../organization/members/UserPermissions";
import useRequest from "../../../../../hooks/useRequest";
import StationImageButton from "./StationImageButton";
import StationImagePopUp from "./StationImagePopUp";

const useStyles = makeStyles((theme) => ({
    cardTitle: {
        display: "flex",
        alignItems: "center"
    },
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "white",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const DeviceDescriptionCardView = ({className}) => {

    const {hasPermission} = useAuth();
    const {t} = useTranslation();
    let history = useHistory();
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const isMounted = useMountComponent();
    const initialValue = useRef({
        deviceInfo: [], state: [], isExternal: null, lastConnection: "", deviceId: null,
        alias: "", loading: true, openPopUp: false, error: ""
    });
    const [
        {deviceInfo, state, isExternal, lastConnection, alias, loading, openPopUp, deviceId, error},
        updateState
    ] = useState(initialValue.current);
    const dispatch = useDispatch();
    const {setError, setSuccess} = useMessage();
    const {handleErrorResponse} = useRequest();
    const [loadingStationImage, setLoadingStationImage] = useState(false);
    const [stationImageUrl, setStationImageUrl] = useState(null);
    const [openImagePopUp, setOpenImagePopUp] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    useEffect(() => {
        loadDeviceInfo();
        loadDeviceButton(selectedStation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation]);

    const loadDeviceButton = useCallback(async (station) => {
        if (station !== null) {
            try {
                setLoadingStationImage(true);
                const url = await getStationImageRequest(station);
                if (url)
                    setStationImageUrl(url);
            } catch (e) {
                setError(e,
                    false,
                    "analyticScreen.deviceDescription.could_not_download_station_image");
            } finally {
                setLoadingStationImage(false);
            }
        }
    }, [setError]);

    const getDeviceInfoFromData = useCallback((data) => {
        return [{
            name: t("analyticScreen.deviceDescription.serial_number"),
            value: data.serial !== "noSerial" ? data.serial : t("not_available"),
            id: 1
        }, {
            name: t("analyticScreen.deviceDescription.model"),
            value: data.model,
            id: 2
        }, {
            name: t("analyticScreen.deviceDescription.id"),
            value: data.id,
            id: 3
        }, {
            name: t("analyticScreen.deviceDescription.fw"),
            value: data.fw && data.fw,
            id: 4
        }].map((item) => ({...item, value: item.value ?? t("not_available")}));
    }, [t]);

    const getStateFromData = useCallback((data) => {
        const timeZone = data.position?.locationInfo?.timeZone;

        return [
            ...(data.isExternal ? [] : [{
                name: t("analyticScreen.deviceDescription.state"),
                value: data.state,
                color: data.state === stationState.active ? "#26D13D" : "#c61218",
                id: 1
            }]),
            {
                clickable: data.position !== undefined,
                name: t("analyticScreen.deviceDescription.location"),
                value: data.position && `${data.position.lat}, ${data.position.long}`,
                id: 2
            },
            {
                name: t("analyticScreen.deviceDescription.battery_level"),
                value: data.battery && `${data.battery}%`,
                id: 3
            },
            {
                name: t("analyticScreen.deviceDescription.last_data"),
                small: true,
                value: getFormattedDateTime(data.lastData, {
                    timeZone, includeTimeZone: true, timeZoneConfig: dataTimeZone
                }),
                id: 4
            }
        ].map((item) => ({...item, value: item.value ?? t("not_available")}));
    }, [t, dataTimeZone]);

    const loadDeviceInfo = useCallback(() => {
        updateState(initialValue.current);
        if (selectedStation !== null) {
            stationInfoRequest(selectedStation, (err, data) => {
                if (isMounted.current) {
                    if (!err) {
                        data = {
                            ...data,
                            isExternal: data.model.startsWith(MODEL_EXTERNAL_PREFIX)
                        };
                        const timeZone = data.position?.locationInfo?.timeZone;
                        updateState({
                            deviceId: data.id,
                            openPopUp: false,
                            deviceInfo: getDeviceInfoFromData(data),
                            state: getStateFromData(data),
                            isExternal: data.isExternal,
                            loading: false,
                            lastConnection: getFormattedDateTime(data.lastConnection, {
                                timeZone, includeTimeZone: true, timeZoneConfig: dataTimeZone
                            }),
                            alias: data.alias,
                            error: ""
                        });
                    } else {
                        updateState({...initialValue.current, loading: false});
                        handleErrorResponse(data, response => {
                            updateState(state => ({...state, error: response.status}));
                            setError(response,
                                false,
                                "analyticScreen.deviceDescription.could_not_update_info");
                        });
                    }
                }
            });
        }
    }, [getStateFromData, getDeviceInfoFromData, handleErrorResponse, setError, initialValue, isMounted, selectedStation,
        dataTimeZone]);

    const onCLosePopUpCallback = useCallback((successChange, data) => {
        updateState(state => ({...state, openPopUp: false, alias: successChange ? data[1] : state.alias}));
        if (successChange) {
            dispatch(updateAliasAction(data[0], data[1]));
        }
    }, [dispatch]);

    const handleOpenPopUp = () => {
        updateState(state => ({...state, openPopUp: true}));
    };

    const locationCallback = useCallback((item) => {
        let data = item.split(",");
        history.push(`/home?lat=${data[0]}&long=${data[1].trim()}&zoom=16`);

    }, [history]);

    const handleEditImage = useCallback(() => {
        loadDeviceButton(selectedStation);
        setOpenImagePopUp(true);
    }, [loadDeviceButton, selectedStation]);

    const onCloseImagePopUp = useCallback(() => {
        setOpenImagePopUp(false);
    }, []);

    const handleDeleteFile = useCallback(()=>{
        setSelectedFile(null);
    },[]);

    const handleFileChange = useCallback((event)=>{
        setSelectedFile(event.target.files[0]);
    },[]);

    const onDeleteImage = useCallback(async ()=>{
        try {
            setLoadingStationImage(true);
            await deleteImageRequest(selectedStation);
            setStationImageUrl(null);
            setSuccess("analyticScreen.deviceDescription.imageDeleted");
        }catch (e) {
            handleErrorResponse(e, response => {
                let errorMessage;
                if (response.status === 403) errorMessage = "analyticScreen.deviceDescription.not_permission_granted";
                else errorMessage = "analyticScreen.deviceDescription.unknown_error";
                setError(response, false, errorMessage);
            });
        }finally {
            setLoadingStationImage(false);
        }
    },[handleErrorResponse, selectedStation, setError, setSuccess]);

    const onUploadImage = useCallback(async (file)=>{
        try {
            setLoadingStationImage(true);
            await uploadImageRequest(selectedStation, file);
            setSuccess("analyticScreen.deviceDescription.imageUploaded");
            handleDeleteFile();
            await loadDeviceButton(selectedStation);
        }catch (e) {
            handleErrorResponse(e, result => {
                let errorMessage;
                switch (result.response?.status) {
                    case 403:
                        errorMessage = "analyticScreen.deviceDescription.not_permission_granted";
                        break;
                    case 413:
                        errorMessage = "organizationPreferences.fileSizeErrorDescription";
                        break;
                    default:
                        errorMessage = "analyticScreen.deviceDescription.unknown_error_uploading";
                }
                setError({}, false, errorMessage);
            });
        }finally {
            setLoadingStationImage(false);
        }
    },[handleDeleteFile, handleErrorResponse, loadDeviceButton, selectedStation, setError, setSuccess]);

    const classes = useStyles();

    return (
        <>
            <CardLayout className={className} loading={loading} error={error && `${t("error")} ${error}`}>
                <CardHeader
                    avatar={
                        isExternal === false && (
                            <StationImageButton
                                url={stationImageUrl}
                                defaultUrl={sensor}
                                loading={loadingStationImage}
                                onclick={handleEditImage}
                            />)
                    }
                    title={<div className={classes.cardTitle}>
                        <Typography data-testid={"device-alias"} variant={"h5"}>{alias}</Typography>
                        {hasPermission(WRITE_ALIAS) && <IconButton
                            data-testid={"device-update-alias"}
                            aria-label="edit" onClick={handleOpenPopUp}>
                            <EditRoundedIcon/>
                        </IconButton>}
                    </div>}
                    subheader={isExternal === false && (
                        <Typography variant={"subtitle1"} data-testid={"device-last-connection"}>
                            {t("analyticScreen.deviceDescription.last_connection")}&nbsp;
                            {lastConnection ? lastConnection : t("not_available")}
                        </Typography>
                    )}
                    disableTypography={true}
                />
                <CardContent>
                    <Grid container direction={"row"} justifyContent={"space-between"}>
                        <Grid item xs={5}>
                            <DeviceInfoList list={deviceInfo}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Divider orientation="vertical" variant="middle"/>
                        </Grid>
                        <Grid item xs={5}>
                            <DeviceInfoList list={state} locationCallback={locationCallback}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <UpdateAliasPopUp
                    stationId={deviceId}
                    open={openPopUp}
                    handleClose={onCLosePopUpCallback}
                    currentAlias={alias}/>
            </CardLayout>
            {openImagePopUp && <StationImagePopUp
                url={stationImageUrl}
                onClose={onCloseImagePopUp}
                onDelete={onDeleteImage}
                onUpload={onUploadImage}
                selectedFile={selectedFile}
                handleDeleteFile = {handleDeleteFile}
                handleFileChange={handleFileChange}
            />}
        </>
    );
};

export default DeviceDescriptionCardView;
